.section {
    width: 100%;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 32px;
    padding-bottom: 5px;
}

.body {
    text-align: left;
    padding: 0px 5px 5px 5px;
}

.sectionContainer {
    background-color: #eef4f9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    padding: 10px;
    justify-content: center;
    text-align: center;
}

.titleContainer {
    background-color: rgb(60, 153, 220);
    width: 100%;
    color: white;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

.subSection {
    padding: 8px;
}

.centerButton {
    display: flex;
    flex-direction: column;
    align-items: center;
}