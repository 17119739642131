.colorContainer {
    width: 200px;
    height: 200px;
    margin: 20px;
}

.container {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.buttons {
    display: flex;
}

.button {
    margin: 10px;
}

.wrong {
    color: red;
}

.right {
    color: green;
}

.title {
    font-size: 3rem;
}