.NavbarItems {
    background: linear-gradient(90deg, rgba(37, 101, 174) 0%, rgb(15, 82, 152) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.navbar-left {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    min-width: 220px;
}

.navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: flex;
    grid-gap: 10px;
    width: 100%;
    justify-content: end;
    padding-right: 10px;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    background: rgb(60, 153, 220);
    border-radius: 4px;
    transition: all 0.2s ease-out;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.navbar-left-icon {
    margin-left: 10px;
    margin-right: 5px;
}

.extraOptions {
    display: none;
}

@media screen and (max-width: 960px) {

    .dropdown {
        display: none;
    }

    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
        margin-right: 10px;
    }

    .nav-menu.active {
        background: rgba(37, 101, 174);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 2;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: rgb(60, 153, 220);
        border-radius: 0;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%);
        margin-left: 20px;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        background: #4ad9e4;
        text-decoration: none;
        color: white;
        font-size: 1.5rem;
    }

    .nav-links-mobile:hover {
        background: white;
        color: #6568f4;
        transition: 250ms;
    }

    .navbar-left-icon {
        position: absolute;
        top: 23px;
        left: 32px;
    }

    .extraOptions {
        display: flex;
        flex-direction: column;
    }

}

.icon {
    color: white;
}