.image {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    padding: 3px;
    background-color: rgb(60, 153, 220);
}

.img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #FFF;
}

.content {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

.container {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card {
    position: relative;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #FFF;
    width: 60vw;
    height: 100%;
}

.cardMobile {
    position: relative;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #FFF;
    width: 95vw;
    height: 100%;

}

.card::before {
    content: "";
    position: absolute;
    height: 150px;
    width: 100%;
    background-color: rgb(60, 153, 220);
    border-radius: 20px 20px 0 0;
}


.cardMobile::before {
    content: "";
    position: absolute;
    height: 150px;
    width: 100%;
    background-color: rgb(60, 153, 220);
    border-radius: 20px 20px 0 0;
}

.mediaIcons {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    flex-direction: column;
}

.mediaIcon {
    align-items: center;
    color: #fff;
    opacity: 0.6;
    margin-top: 10px;
    transition: all 0.3s ease;
}

.mediaIcon:hover {
    opacity: 1;
}

.nameProfession {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
}

.name {
    font-size: 20px;
    font-weight: 600;
}

.profession {
    font-size: 15px;
    font-weight: 500;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.contactButtonMobile {
    outline: none;
    border: none;
    padding: 10px 10px;
    border-radius: 20px;
    font-size: 24px;
    transition: all 0.1s ease;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: 10px;
    color: #FFF;
    background-color: rgb(60, 153, 220);
    box-shadow: 0 5px 0 rgb(24, 63, 92);
    flex-direction: column;
    width: 100%;
}

.contactButton {
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 24px;
    transition: all 0.1s ease;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: 10px;
    color: #FFF;
    background-color: rgb(60, 153, 220);
    box-shadow: 0 5px 0 rgb(24, 63, 92);
    flex-direction: column;
    width: 100%;
    min-width: 360px;
}

.contactButton:active {
    box-shadow: none;
    transform: translateY(5px);
}

.contactButton:hover {
    background: rgb(102, 178, 250);
}

.contactButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    flex-direction: row;
}

.aTag {
    text-decoration: none;
}

.icon {
    color: white;
    padding: 10px 25px;
}

.logo {
    height: 24px;
    margin-left: 5px;
}

.description {
    padding: 10px;
}

.inButtonBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.email {
    text-decoration: none;
    color: white;
}