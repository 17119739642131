.links {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}

.link {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 8px;
    align-items: center;
    text-align: center;
}

.challenge {
    min-width: 250px;
    color: black;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 1px solid white;
    border-radius: 8px;
}

.title {
    font-size: 2em;
    background-color: rgb(60, 153, 220);
    border: 1px solid rgb(60, 153, 220);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    text-align: center;
}

.description {
    padding: 5px;
}