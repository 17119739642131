.img {
    height: 300px;
    border-radius: 25px;
    border: 1px;
    opacity: 1;
}


.transition1 {
    transition: all 1.1s ease;
}

.transition2 {
    transition: all 1.5s ease;
}

.transition3 {
    transition: all 1.9s ease;
}

.leftSide {
    padding: 10px;
    padding-right: 250px;
    height: 320px;
}

.rightSide {
    padding: 10px;
    padding-left: 250px;
    height: 320px;
}

.transitionEnd {
    margin: 0px;
    padding: 10px;
}

.imgEnd {
    opacity: 1;
}

.columnContainer {
    width: 50%;
}

.columnLeft {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.columnRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.columns {
    width: 100vw;
    display: flex;
    justify-items: center;
    overflow: hidden;
}

.dividerHorizontalContainer {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.dividerHorizontal {
    width: 100%;
    height: 3px;
    background-color: lightgrey;
}

.dividerVerticleContainer {
    padding-top: 20px;
    padding-bottom: 20px;
}

.dividerVerticle {
    height: 100%;
    background-color: grey;
    width: 5px;
}

.cardContainer {
    margin: 0px;
}

.textCard {
    position: relative;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #FFF;
    width: 40vw;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    justify-content: center;
}

.mobileTextCard {
    position: relative;
    border-radius: 20px;
    background: #FFF;
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    justify-content: center;
}

.title {
    font-size: 30px;
    padding: 10px;
}

.paragraph {
    font-size: 14px;
    padding: 10px;
}


.button {
    outline: none;
    border: none;
    color: #FFF;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 24px;
    transition: all 0.1s ease;
    cursor: pointer;
    background-color: rgb(60, 153, 220);
    box-shadow: 0 5px 0 rgb(24, 63, 92);
    margin-top: 10px;
}

.button:active {
    box-shadow: none;
    transform: translateY(5px);
}

.button:hover {
    background: rgb(102, 178, 250);
}

.cardContent {
    position: relative;
    opacity: 1;
}

.mobileTextCardWrapper {
    overflow: hidden;
    border-radius: 20px;
}

.portfolioCardContentWrapper::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url("../../images/portrait2.jpg");
    background-size: 100%;
    background-position: right 0px bottom 0px;
    opacity: 0.35;
    filter: blur(2px);
    overflow: hidden;
    border-radius: 20px;
}

.aboutCardContentWrapper::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url("../../images/frontend-uncropped.jpg");
    background-size: 100%;
    background-position: center;
    opacity: 0.35;
    filter: blur(2px);
    background-repeat: no-repeat;
    background-color: #3A3F52;
    border-radius: 20px;
}

.contactCardContentWrapper::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url("../../images/Contact-Me.jpg");
    background-size: 100%;
    background-position: right 0px bottom 15px;
    opacity: 0.35;
    filter: blur(2px);
    border-radius: 20px;
}