.dropdownInput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    user-select: none;
    cursor: pointer;
    color: white;
    background-color: rgb(60, 153, 220);
    border-radius: 5px;
}

.textContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-right: 5px;
}

.option {
    cursor: pointer;
    background-color: rgb(60, 153, 220);
    padding: 10px;
    color: white;
    font-size: 0.875em;
}

.option:hover {
    background-color: rgb(102, 178, 250);
}

.dropdownContainer {
    text-align: left;
    position: relative;
    margin-right: 5px;
    min-width: 100px;
}

.dropdownOptions {
    position: absolute;
    width: 100%;
    border: 0px solid #ccc;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: auto;
    background-color: #fff;
    z-index: 2;
}

.caret {
    margin-top: 10px;
    margin-bottom: 10px;
    min-width: 32px;
}

.text {
    margin-right: 5px;
}

.noBottomBorder {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 1px solid #ccc;
}