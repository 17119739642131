.background {
    background-image: url('../../images/grey.png');
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    min-width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    filter: blur(6px);
}