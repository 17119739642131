.carousel {
    scroll-snap-type: x mandatory;
    scroll-padding: 0 24px;
}

.title {
    text-align: center;
    font-size: 56px;
    color: white;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    font-family: "Calibri", "Candara", "Segoe", "Segoe UI", "Optima", Arial, sans-serif;
}

.item {
    scroll-snap-align: start;
}

.image {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    padding: 3px;
    background-color: rgb(60, 153, 220);
}

.img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #FFF;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;

}

.content {
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
}

.container {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
}

.extraSpace {
    height: 14px;
}

.card {
    position: relative;
    border-radius: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #FFF;
    width: 300px;
    height: 550px;
}

.card::before {
    content: "";
    position: absolute;
    height: 120px;
    width: 100%;
    background: rgb(60, 153, 220);
    border-radius: 20px 20px 0 0;
}

.mediaIcons {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    flex-direction: column;
}

.mediaIcon {
    align-items: center;
    color: #fff;
    opacity: 0.6;
    margin-top: 10px;
    transition: all 0.3s ease;
}

.mediaIcon:hover {
    opacity: 1;
}

.nameProfession {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    text-align: center;
}

.name {
    font-size: 20px;
    font-weight: 600;
}

.profession {
    font-size: 15px;
    font-weight: 500;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.button {
    outline: none;
    border: none;
    color: #FFF;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 14px;
    transition: all 0.1s ease;
    cursor: pointer;
    background-color: rgb(60, 153, 220);
    box-shadow: 0 5px 0 rgb(24, 63, 92);
}

.button:active {
    box-shadow: none;
    transform: translateY(5px);
}

.button:hover {
    background: rgb(102, 178, 250);
}

.tech {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.techButton {
    background: #932f7c;
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 18px;
    transition: all 0.1s ease;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: 10px;
    color: #FFF;
    background-color: rgb(60, 153, 220);
    box-shadow: 0 5px 0 rgb(24, 63, 92);
}

.techButton:active {
    box-shadow: none;
    transform: translateY(5px);
}

.techButton:hover {
    background: rgb(102, 178, 250);
}

.techButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
    flex-direction: column;
}

.aTag {
    text-decoration: none;
}

.reactLogo {
    color: #00d8ff;
}

.logo {
    height: 24px;
    margin-left: 5px;
}

.swiper {
    width: 90vw;
}

.description {
    padding: 10px;
}