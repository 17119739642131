.diceBox {
  padding-right: 20%;
  padding-left: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.rollButtonContainer {
  width: 30%;
}

.diceRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rollButton {
  outline: none;
  border: none;
  color: #fff;
  transition: all 0.1s ease;
  cursor: pointer;
  background-color: rgb(60, 153, 220);
  box-shadow: 0 5px 0 rgb(24, 63, 92);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 50px;
  width: 100%;
  margin: 10px;
  border-radius: 6px;
}

.rollButton:active {
  box-shadow: none;
  transform: translateY(5px);
}

.rollButton:hover {
  background: rgb(102, 178, 250);
}

.title {
  font-size: large;
  padding: 5px;
  background-color: rgb(60, 153, 220);
  color: white;
  border-radius: 5px;
  margin: 10px;
}

.customizeSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.inputBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inputBoxContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.inputContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.presetsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.customArea {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  margin-right: 5px;
  margin-left: 5px;
  width: 30px;
}

.diceLabel {
  min-width: 60px;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
}
